import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Autowired, ExternalProduct } from '../../ic-core';
import { ExternalProductRepository } from '../repositories/externalProduct.repository';

@Injectable({
  providedIn: 'root',
})
export class ExternalProductService {
  @Autowired()
  protected externalProductRepository: ExternalProductRepository;

  async createExternalProduct(externalProduct: ExternalProduct): Promise<ExternalProduct> {
    try {
      externalProduct = await this.externalProductRepository.create(externalProduct);
    } catch (err) {
      throw err;
    }
    return externalProduct;
  }

  async updateExternalProduct(dataChanged: ExternalProduct): Promise<void> {
    await this.externalProductRepository.update(dataChanged);
  }

  async deleteExternalProduct(id): Promise<void> {
    await this.externalProductRepository.delete(id);
  }

  searchExternalProducts(query: any): Observable<ExternalProduct[]> {
    return this.externalProductRepository.search(query);
  }

  async getExternalProduct(externalProductId: string): Promise<ExternalProduct> {
    return await this.externalProductRepository.get(externalProductId).toPromise();
  }

  generateEmptyExternalProduct(): ExternalProduct {
    return new ExternalProduct({
      name: null,
      type: null,
      description: null,
      unit: null,
    });
  }
}
