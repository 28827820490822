import { Injectable } from '@angular/core';
import { AbstractRepository, Inventory } from '../../ic-core';

@Injectable({
  providedIn: 'root',
})
export class InventoryRepository extends AbstractRepository<Inventory> {
  constructor() {
    super(Inventory);
  }
}
