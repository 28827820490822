import { Injectable } from '@angular/core';
import { Autowired, Inventory } from '../../ic-core';
import { InventoryHistory } from '../../ic-core';
import { InventoryHistoryRepository } from '../repositories/inventory-history.repository';
import { InventoryRepository } from '../repositories/inventory.repository';

@Injectable({
  providedIn: 'root',
})
export class InventoryService {
  @Autowired()
  protected inventoryRepository: InventoryRepository;

  @Autowired()
  protected inventoryHistoryRepository: InventoryHistoryRepository;

  async createInventory(inventory: Inventory): Promise<Inventory> {
    try {
      inventory = await this.inventoryRepository.create(inventory);
    } catch (err) {
      throw err;
    }
    return inventory;
  }

  async updateInventory(dataChanged: Inventory): Promise<void> {
    await this.inventoryRepository.update(dataChanged);
  }

  async searchInventories(query: any): Promise<Inventory[]> {
    return await this.inventoryRepository.search(query).toPromise();
  }

  async searchInventoryHistories(query: any, inventoryId: string): Promise<InventoryHistory[]> {
    return await this.inventoryHistoryRepository
      .search(query, 'inventories/' + inventoryId)
      .toPromise();
  }

  async getInventory(inventoryId: string): Promise<Inventory> {
    return await this.inventoryRepository.get(inventoryId).toPromise();
  }

  generateEmptyInventory(): Inventory {
    return new Inventory({
      productId: null,
      transactionDate: null,
      creditAmount: 0,
      debitAmount: 0,
      location: null,
    });
  }
}
