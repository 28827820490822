import { Injectable } from '@angular/core';
import { InventoryHistory } from '../../ic-core';
import { AbstractDeepRepository } from '../../ic-core/repository/abstract.deep.repository';

@Injectable({
  providedIn: 'root',
})
export class InventoryHistoryRepository extends AbstractDeepRepository<InventoryHistory> {
  constructor() {
    super(InventoryHistory);
  }
}
