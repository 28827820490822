import { Injectable } from '@angular/core';
import { AbstractApi, ExternalProduct } from '../../ic-core';

@Injectable({
  providedIn: 'root',
})
export class ExternalProductApi extends AbstractApi<ExternalProduct> {
  constructor() {
    super(ExternalProduct);
  }

  async search(query: object, deepPath?: string): Promise<ExternalProduct[]> {
    // @ts-ignore
    if (!!query.attachPrice) {
      return await this.request<ExternalProduct[]>('GET', `${this.url}`);
    } else {
      return await super.search(query);
    }
  }
}
