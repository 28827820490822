import { Injectable } from '@angular/core';
import { AbstractRepository, Autowired, ExternalProduct } from '../../ic-core';
import { ExternalProductApi } from '../api/externalProduct.api';

@Injectable({
  providedIn: 'root',
})
export class ExternalProductRepository extends AbstractRepository<ExternalProduct> {
  @Autowired()
  api: ExternalProductApi;

  constructor() {
    super(ExternalProduct);
  }
}
